import React from "react";
import "./DiscoverSolutin.css"; // Create this file for styling

const DiscoverSolution = () => {
  const handleScrollToSection = () => {
    const section = document.getElementById("section-where");
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <section id="discover-solution-section">
      <div className="DiscoverSolutin" onClick={handleScrollToSection}>
        <a href="#section-where">Discover Solutions</a>
      </div>
    </section>
  );
};

export default DiscoverSolution;
