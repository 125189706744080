import React from "react";
import "./SectionWho.css";
import RedoOutlinedIcon from "@mui/icons-material/RedoOutlined";
function SectionWho() {
  return (
    <>
      <div className="section-who-main">
        <section id="section-who" className="section-who">
          <table className="section-who-table-1">
            <tbody>
              <tr>
                <td colSpan="1" rowSpan="1" className="section-who-box ">
                  <h1 className="section-who-title">Who are we?</h1>
                  <p>
                    A passionate team of ESG experts with diverse backgrounds
                    and a shared vision for a sustainable future.
                  </p>
                </td>
                <td className="arrow-cell">
                  <div className="arrow">
                    <RedoOutlinedIcon />
                  </div>{" "}
                  {/* Or use an icon */}
                </td>
                <td rowSpan="1" colSpan="1" className="section-who-box">
                  <h1 className="section-who-title">Who we want to become?</h1>
                  <p>
                    We aspire to be a catalyst for global change, empowering
                    businesses of all sizes to embrace a sustainable future.
                  </p>
                  <div className="box-grid">
                    <div className="box">
                      {" "}
                      {/* <ExtensionOutlinedIcon className="icon-why" /> */}
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <table className="section-who-table">
            <tbody>
              <tr>
                <td colSpan="4" className="section-who-title-cell">
                  <h1 className="section-who-title">Our Values</h1>
                </td>
              </tr>
              {/* Four columns */}
              <tr className="four-columns min-w-full">
                <td className="section-who-btn-cell">
                  <h1 className="four-colum-one">01</h1>
                  <h3 className="four-colum-one">People First </h3>
                  <h6 className="four-colum-one">
                    We prioritize building a thriving community within Seges and
                    with our clients.
                  </h6>
                </td>
                <td className="section-who-btn-cell">
                  <h1 className="four-colum-one">02</h1>
                  <h3 className="four-colum-one">Planetary Stewardship</h3>
                  <h6 className="four-colum-one">
                    We advocate for responsible environmental practices and
                    resource management.
                  </h6>
                </td>
                <td className="section-who-btn-cell">
                  <h1 className="four-colum-one">03</h1>
                  <h3 className="four-colum-one">Prosperity for All </h3>
                  <h6 className="four-colum-one">
                    We believe in an economic system that rewards sustainable
                    and ethical business models.
                  </h6>
                </td>
                <td className="section-who-btn-cell">
                  <h1 className="four-colum-one">04</h1>
                  <h3 className="four-colum-one">
                    Collaboration & Transparency{" "}
                  </h3>
                  <h6 className="four-colum-one">
                    We value open communication and working hand-in-hand with
                    our clients.
                  </h6>
                </td>
              </tr>
            </tbody>
            {/* <tfoot>
              <tr>
                <td colSpan="3">
                  <button className="section-who-footer-btn section-who-footer-btn-1">
                    Discover Solution
                  </button>
                </td>
                <td colSpan="2">
                  <p className="section-who-footer-btn-2">www.seges.earth</p>
                </td>
              </tr>
            </tfoot> */}
          </table>
        </section>
      </div>
    </>
  );
}

export default SectionWho;
