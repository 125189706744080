import React from "react";
import "./SectionWhy.css";

import tailoured_solution from "../../assets/Seges-Icons/8.png";
import tech_driven_approach from "../../assets/Seges-Icons/9.png";
import focus_on_impact from "../../assets/Seges-Icons/10.png";
import collab from "../../assets/Seges-Icons/11.png";

function SectionWhy() {
  return (
    <div>
      {" "}
      <section id="section-why" className="section-why">
        <table className="section-why-table">
          <tbody>
            <tr>
              <td colSpan="1" className="section-why-box">
                <h1>Why ESG is essential?</h1>
                <ul>
                  <li>
                    Attract and retain investors who increasingly prioritize ESG
                    performance.
                  </li>
                  <li>Enhance brand reputation and customer loyalty.</li>
                  <li>Manage and mitigate environmental and social risks.</li>
                  <li>Foster a culture of innovation and long-term growth.</li>
                </ul>
              </td>
              <td rowSpan="2" colSpan="2" className="section-why-box">
                <h1>Why engage with Seges?</h1>
                <div className="box-grid">
                  <div className="box">
                    {" "}
                    <img
                      src={tailoured_solution}
                      alt="Materiality Assessment Icon"
                      className="seges-icons-why"
                    />{" "}
                    <h1>Tailored Solutions</h1>
                    <p>
                      We understand every business is unique, and we craft
                      strategies that fit your specific needs.{" "}
                    </p>
                  </div>
                  <div className="box">
                    {" "}
                    <img
                      src={tech_driven_approach}
                      alt="Materiality Assessment Icon"
                      className="seges-icons-why"
                    />{" "}
                    <h1>Tech-driven Approach</h1>
                    <p>
                      Our expertise leverages the power of data and technology
                      for efficient ESG implementation.
                    </p>
                  </div>
                </div>
                <div className="box-grid">
                  <div className="box">
                    {" "}
                    {/* <AdjustTwoToneIcon className="icon-why" /> */}
                    <img
                      src={focus_on_impact}
                      alt="Materiality Assessment Icon"
                      className="seges-icons-why"
                    />{" "}
                    <h1>Focus on Impact</h1>
                    <p>
                      We go beyond compliance, helping you make tangible
                      positive change.
                    </p>
                  </div>
                  <div className="box">
                    {" "}
                    <img
                      src={collab}
                      alt="Materiality Assessment Icon"
                      className="seges-icons-why"
                    />{" "}
                    <h1>Collaborative Partnership </h1>
                    <p>
                      Our expertise leverages the power of data and technology
                      for efficient ESG implementation.
                    </p>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td className="section-why-box">
                <h1>
                  Analyze Report & Disclose across International Standards
                </h1>
                <div className="box-container">
                  <div className="box-1">BRSR</div>
                  <div className="box-2">IFRS</div>
                  <div className="box-3">GRI</div>
                  <div className="box-4">SASB</div>
                  <div className="box-5">TCFD</div>
                  <div className="box-6">ISSB</div>
                  <div className="box-7">PRI</div>
                  <div className="box-8">SBTi</div>
                  <div className="box-9">CSDDD</div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </section>
    </div>
  );
}

export default SectionWhy;
