import React from "react";
import Slider from "react-slick";
import "../slider.css";

import "./SectionWhen.css";

// Custom arrow components
const NextArrow = (props) => {
  const { className, onClick } = props;
  return (
    <div onClick={onClick}>
      <span className={className} style={{ ...style, right: 0 }}>
        &#9654;
      </span>{" "}
      {/* Right arrow */}
    </div>
  );
};

const PrevArrow = (props) => {
  const { className, onClick } = props;
  return (
    <div onClick={onClick}>
      <span className={className} style={{ ...style, right: 0 }}>
        &#9664;
      </span>
      {/* Left arrow */}
    </div>
  );
};

const style = {
  width: "30px",
  height: "30px",
  borderRadius: "50%",
  lineHeight: "30px",
  textAlign: "center",
  cursor: "pointer",
  color: "#000000 !important", // Arrow color
  background: "#000000 !important", // Arrow color
  // background: "#fff", // Background color for contrast
  zIndex: 1,
};

function SectionWhen() {
  const settings = {
    arrows: true,
    dots: true,
    infinite: true,
    speed: 1500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 20000,
    fade: true,
    cssEase: "ease-in-out",
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  return (
    <Slider {...settings}>
      <section className="section-when-main">
        <section id="section-when" className="section-when">
          <div className="section-when-title">
            {" "}
            <h1 className="">When does a business need Seges?</h1>
          </div>

          <table className="section-when-table-1">
            <tbody>
              <tr>
                <td colSpan="1" rowSpan="1" className="section-when-box ">
                  <h1>01</h1>
                  <h3>Early Stages</h3>
                  <h6>
                    <b>Foundational ESG Strategy:</b>&nbsp;For startups and
                    young businesses, incorporating ESG principles from the
                    outset can differentiate them in the market and attract
                    conscious investors.
                  </h6>{" "}
                  <h6>
                    <b>Risk Mitigation:</b>&nbsp;Identifying potential ESG risks
                    early on allows for proactive measures to protect the
                    business's reputation and bottom line.
                  </h6>
                </td>
                <td colSpan="1" rowSpan="1" className="section-when-box ">
                  <h1>02</h1>
                  <h3>Growth Stage</h3>
                  <h6>
                    <b>Investor Relations:</b>&nbsp;As businesses expand, ESG
                    becomes increasingly important for attracting investors and
                    securing funding.
                  </h6>{" "}
                  <h6>
                    <b>Supply Chain Management:</b>&nbsp;Managing the ESG
                    performance of suppliers can enhance a company's overall
                    sustainability profile.
                  </h6>{" "}
                  <h6>
                    <b>Market Differentiation:</b>&nbsp;Leveraging ESG as a
                    competitive advantage to attract environmentally conscious
                    customers.
                  </h6>
                </td>
              </tr>
              <tr>
                <td colSpan="12" rowSpan="12" className="who-footer">
                  <p>
                    Regardless of the stage, any business can benefit from an
                    ESG partner like Seges to navigate complexities, achieve
                    sustainability goals, and unlock new opportunities.{" "}
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        </section>
      </section>

      <section className="section-when-main">
        <section id="section-when" className="section-when">
          <div className="section-when-title">
            {" "}
            <h1 className="">When does a business need Seges?</h1>
          </div>

          <table className="section-when-table-1">
            <tbody>
              <tr>
                <td colSpan="1" rowSpan="1" className="section-when-box ">
                  <h1>03</h1>
                  <h3>Mature Stage</h3>
                  <h6>
                    <b>ESG Reporting and Disclosure:</b>&nbsp;Meeting regulatory
                    requirements and enhancing transparency.
                  </h6>{" "}
                  <h6>
                    <b>Stakeholder Engagement:</b>&nbsp;Building strong
                    relationships with stakeholders, including employees,
                    customers, and communities.
                  </h6>{" "}
                  <h6>
                    <b> Circular Economy Transition:</b>&nbsp;Embracing
                    sustainable business models and reducing environmental
                    impact.
                  </h6>
                </td>
                <td colSpan="1" rowSpan="1" className="section-when-box ">
                  <h1>04</h1>
                  <h3>Crisis Management</h3>
                  <h6>
                    <b>ESG Scandals:</b>&nbsp;Addressing negative publicity and
                    restoring trust through effective crisis management.
                  </h6>{" "}
                  <h6>
                    <b>Regulatory Compliance:</b>&nbsp;Ensuring adherence to
                    evolving ESG regulations.
                  </h6>{" "}
                  <h6>
                    <b>Market Differentiation:</b>&nbsp;Leveraging ESG as a
                    competitive advantage to attract environmentally conscious
                    customers.
                  </h6>
                </td>
              </tr>
              <tr>
                <td colSpan="12" rowSpan="12" className="who-footer">
                  <p>
                    Regardless of the stage, any business can benefit from an
                    ESG partner like Seges to navigate complexities, achieve
                    sustainability goals, and unlock new opportunities.{" "}
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        </section>
      </section>
    </Slider>
  );
}

export default SectionWhen;
