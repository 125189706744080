import React from "react";
import Slider from "react-slick";
import "./SectionWhat.css";
import "../slider.css";

// Custom arrow components
const NextArrow = (props) => {
  const { className, onClick } = props;
  return (
    <div onClick={onClick}>
      <span className={className} style={{ ...style, right: 0 }}>
        &#9654;
      </span>{" "}
      {/* Right arrow */}
    </div>
  );
};

const PrevArrow = (props) => {
  const { className, onClick } = props;
  return (
    <div onClick={onClick}>
      <span className={className} style={{ ...style, right: 0 }}>
        &#9664;
      </span>
      {/* Left arrow */}
    </div>
  );
};

const style = {
  width: "30px",
  height: "30px",
  borderRadius: "50%",
  lineHeight: "30px",
  textAlign: "center",
  cursor: "pointer",
  color: "#000000 !important", // Arrow color
  background: "#000000 !important", // Arrow color
  // background: "#fff", // Background color for contrast
  zIndex: 1,
};

const SectionWhat = () => {
  const settings = {
    arrows: true,
    dots: true,
    infinite: true,
    speed: 1500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 20000,
    fade: true,
    cssEase: "ease-in-out",
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };
  return (
    <div className="Section-What-Main-Container">
      <Slider {...settings}>
        {/* SECTION 1 */}

        <div>
          <section id="section-what" className="section-what">
            <table className="section-what-table">
              <tbody>
                <tr>
                  <td colSpan="4" className="section-what-title-cell">
                    <h1 className="section-what-title">
                      What we sow is what we reap...
                    </h1>
                  </td>
                </tr>
                <tr>
                  <td colSpan="4" className="section-what-description-cell">
                    <p className="section-what-description">
                      Seges empowers businesses to sow the seeds of ESG, reaping
                      a bountiful harvest of long-term value for stakeholders
                      and our planet.
                    </p>
                  </td>
                </tr>

                {/* Four columns */}
                <tr className="four-columns-what">
                  <td className="section-what-btn-cell">
                    <h1 className="four-colum-one">01</h1>
                    <h3 className="four-colum-one">Purpose</h3>
                    <h6 className="four-colum-one">
                      Ignite a sustainability revolution where businesses become
                      powerful forces for good.
                    </h6>
                  </td>
                  <td className="section-what-btn-cell">
                    <h1 className="four-colum-one">02</h1>
                    <h3 className="four-colum-one">Mission</h3>
                    <h6 className="four-colum-one">
                      To empower businesses and communities with sustainable
                      solutions that prioritize people, planet, and prosperity.
                    </h6>
                  </td>
                  <td className="section-what-btn-cell">
                    <h1 className="four-colum-one">03</h1>
                    <h3 className="four-colum-one">Vision</h3>
                    <h6 className="four-colum-one">
                      A world where ESG principles are seamlessly integrated
                      into every business decision, leading to a thriving planet
                      and equitable society.
                    </h6>
                  </td>
                  <td className="section-what-btn-cell">
                    <h1 className="four-colum-one">04</h1>
                    <h3 className="four-colum-one">Values</h3>
                    <h6 className="four-colum-one">
                      People First, Planetary Stewardship, Prosperity for All,
                      Collaboration & Transparency.
                    </h6>
                  </td>
                </tr>
              </tbody>
              <tfoot className="logo-linkedin-what-container">
                <h6 className="logo-linkedin-what">
                  {" "}
                  <a
                    href="https://www.linkedin.com/in/jigar-motta-7826a42aa/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 448 512"
                    >
                      <path d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z" />
                    </svg>
                  </a>{" "}
                </h6>
              </tfoot>
            </table>
          </section>
        </div>
        <div>
          {/* SECTION 2 */}

          <section className="section-what-2">
            <table className="section-what-table">
              <tbody>
                <tr>
                  <td colSpan="4" className="section-what-title-cell">
                    <h1 className="section-what-title text-center">
                      What is ESG for us?
                    </h1>
                  </td>
                </tr>

                {/* Four columns */}
                <tr className="section-what-four-columns-2">
                  <td className="section-what-btn-cell-2">
                    <h1 className="four-colum-one">01</h1>
                    <h3 className="four-colum-one">Environmental</h3>
                    <h6 className="four-colum-one">
                      Minimizing your environmental footprint through practices
                      like reducing emissions, waste management, and resource
                      efficiency.
                    </h6>
                  </td>
                  <td className="section-what-btn-cell-2"></td>
                  <td className="section-what-btn-cell-2"></td>
                  <td className="section-what-btn-cell-2">
                    <h1 className="four-colum-one">02</h1>
                    <h3 className="four-colum-one">Governance</h3>
                    <h6 className="four-colum-one">
                      Implementing ethical, transparent, and accountable
                      leadership structures.
                    </h6>
                  </td>
                </tr>
                <tr className="section-what-four-columns-2 -order-1">
                  <td className="section-what-btn-cell-2">
                    <h1 className="four-colum-one">03</h1>
                    <h3 className="four-colum-one">Social</h3>
                    <h6 className="four-colum-one">
                      Prioritizing people and communities, ensuring fair labor
                      practices, diversity and inclusion, and positive social
                      impact initiatives.
                    </h6>
                  </td>
                  <td className="section-what-btn-cell-2"></td>
                  <td className="section-what-btn-cell-2"></td>
                  <td className="section-what-btn-cell-2"></td>
                </tr>
              </tbody>
              <tfoot className="logo-linkedin-what-container">
                <h6 className="logo-linkedin-what">
                  {" "}
                  <a
                    href="https://www.linkedin.com/in/jigar-motta-7826a42aa/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 448 512"
                    >
                      <path d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z" />
                    </svg>
                  </a>{" "}
                </h6>
              </tfoot>
            </table>
          </section>
        </div>

        {/* SECTION 3 */}
        <div>
          <section className="section-what-3">
            <table className="section-what-table">
              <tbody>
                <tr>
                  <td colSpan="4" className="section-what-title-cell">
                    <h1 className="section-what-title">
                      What seeds we sow to assist your growth?{" "}
                    </h1>
                  </td>
                </tr>
                <tr>
                  <td colSpan="4" className="section-what-description-cell">
                    <p className="section-what-description"></p>
                  </td>
                </tr>
                {/* Four columns */}
                <tr className="four-columns-what">
                  <td className="section-what-btn-cell-3">
                    <h1 className="four-colum-one">01</h1>
                    <h3 className="four-colum-one">Strategy Alignment</h3>
                    <h6 className="four-colum-one">
                      Develop a comprehensive ESG strategy aligned with your
                      goals and industry.
                    </h6>
                  </td>
                  <td className="section-what-btn-cell-3">
                    <h1 className="four-colum-one">02</h1>
                    <h3 className="four-colum-one">Reporting Expertise</h3>
                    <h6 className="four-colum-one">
                      Navigate ESG reporting with expertise and user-friendly
                      technology integration.
                    </h6>
                  </td>
                  <td className="section-what-btn-cell-3">
                    <h1 className="four-colum-one">03</h1>
                    <h3 className="four-colum-one">Sustainable Solutions</h3>
                    <h6 className="four-colum-one">
                      Implement sustainable solutions that create lasting
                      impact.
                    </h6>
                  </td>
                  <td className="section-what-btn-cell-3">
                    <h1 className="four-colum-one">04</h1>
                    <h3 className="four-colum-one">Impact Measurement</h3>
                    <h6 className="four-colum-one">
                      Measure and quantify the positive outcomes of your ESG
                      initiatives.
                    </h6>
                  </td>
                </tr>
              </tbody>
              <tfoot className="logo-linkedin-what-container">
                <h6 className="logo-linkedin-what">
                  {" "}
                  <a
                    href="https://www.linkedin.com/in/jigar-motta-7826a42aa/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 448 512"
                    >
                      <path d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z" />
                    </svg>
                  </a>{" "}
                </h6>
              </tfoot>
            </table>
          </section>
        </div>
      </Slider>
    </div>
  );
};

export default SectionWhat;
