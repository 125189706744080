import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import Navbar from "./components/Navbar/Navbar";
import SectionWhat from "./components/SectionWhat/SectionWhat";
import SectionWhy from "./components/SectionWhy/SectionWhy";
import SectionHow from "./components/SectionHow/SectionHow";
import SectionWho from "./components/SectionWho/SectionWho";
import SectionWhen from "./components/SectionWhen/SectionWhen";
import SectionWhere from "./components/SectionWhere/SectionWhere";
import ScrollToTop from "./components/scroll-top/ScrollToTop.js";
import "./App.css";
import DiscoverSolution from "./components/scroll-top/DiscoverSolution.js";

const App = () => {
  return (
    <Router>
      <div>
        <Navbar />
      </div>
      <div>
        <main className="app-main-container">
          <div>
            <SectionWhat id="section-what" />
          </div>
          <div>
            <SectionWhy id="#section-why" />
          </div>
          <div>
            <SectionHow id="section-how" />
          </div>
          <div>
            <SectionWho id="section-who" />
          </div>
          <div>
            <SectionWhen id="section-when" />
          </div>
          <div>
            <SectionWhere id="section-where" />
          </div>
        </main>
        <ScrollToTop />
        <DiscoverSolution id="section-where" />
      </div>
    </Router>
  );
};

export default App;
