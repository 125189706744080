import React from "react";
import "./SectionHow.css";
import "../slider.css";

import Slider from "react-slick";
import MaterialityiconAssessment from "../../assets/Seges-Icons/Materiality_Assessment_iconAssessment.png";
import ESG_Strategy_Roadmap_icon from "../../assets/Seges-Icons/ESG_Strategy_Roadmap_icon.png";
import ESG_Management_icon from "../../assets/Seges-Icons/ESG_Management_icon.png";
import Stakeholder_Engagement_icon from "../../assets/Seges-Icons/Stakeholder_Engagement_icon.png";
import Increased_Profitability_icon from "../../assets/Seges-Icons/Increased_Profitability_icon.png";
import ReducedRisk_iconwry from "../../assets/Seges-Icons/ReducedRisk_iconwry.png";
import Enhanced_Reputation_icon from "../../assets/Seges-Icons/Enhanced_Reputation_icon.png";
import future_proof from "../../assets/Seges-Icons/12.png";

// Custom arrow components
const NextArrow = (props) => {
  const { className, onClick } = props;
  return (
    <div onClick={onClick}>
      <span className={className} style={{ ...style, right: 0 }}>
        &#9654;
      </span>{" "}
      {/* Right arrow */}
    </div>
  );
};

const PrevArrow = (props) => {
  const { className, onClick } = props;
  return (
    <div onClick={onClick}>
      <span className={className} style={{ ...style, right: 0 }}>
        &#9664;
      </span>
      {/* Left arrow */}
    </div>
  );
};

const style = {
  width: "30px",
  height: "30px",
  borderRadius: "50%",
  lineHeight: "30px",
  textAlign: "center",
  cursor: "pointer",
  color: "#000000 !important", // Arrow color
  background: "#000000 !important", // Arrow color
  // background: "#fff", // Background color for contrast
  zIndex: 1,
};

function SectionHow() {
  const SectionWhySettings = {
    arrows: true,
    dots: true,
    infinite: true,
    speed: 1500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 20000,
    fade: true,
    cssEase: "ease-in-out",
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };
  return (
    <Slider {...SectionWhySettings}>
      {/* 1 */}
      <div>
        {" "}
        <section id="section-how" className="section-how">
          <table className="section-how-table">
            <tbody>
              <tr>
                <td rowSpan="2" colSpan="2" className="section-how-box">
                  <h1>How we empower your success?</h1>
                  <div className="box-grid-how">
                    <div className="box-how">
                      {" "}
                      {/* <PersonSearchOutlinedIcon className="icon-why" /> */}
                      {/* // eslint-disable-next-line react/jsx-pascal-case */}
                      <img
                        src={MaterialityiconAssessment}
                        alt="Materiality Assessment Icon"
                        className="seges-icons"
                      />
                      <h1>Materiality Assessment</h1>
                      <p>
                        Identify the most critical ESG issues for your industry
                        and business.
                      </p>
                    </div>
                    <div className="box-how">
                      {" "}
                      {/* <NearMeOutlinedIcon className="icon-why" /> */}
                      <img
                        src={ESG_Strategy_Roadmap_icon}
                        alt="Materiality Assessment Icon"
                        className="seges-icons"
                      />
                      <h1>ESG Strategy & Roadmap </h1>
                      <p>
                        Develop a clear plan to achieve your sustainability
                        goals.
                      </p>
                    </div>
                  </div>
                  <div className="box-grid-how">
                    <div className="box-how">
                      {" "}
                      {/* <DescriptionOutlinedIcon className="icon-why" /> */}
                      <img
                        src={ESG_Management_icon}
                        alt="Materiality Assessment Icon"
                        className="seges-icons"
                      />
                      <h1>ESG Reporting & Data Management</h1>
                      <p>
                        Simplify reporting with cutting-edge software
                        integration.
                      </p>
                    </div>
                    <div className="box-how">
                      {" "}
                      {/* <HubOutlinedIcon className="icon-why" /> */}
                      <img
                        src={Stakeholder_Engagement_icon}
                        alt="Materiality Assessment Icon"
                        className="seges-icons"
                      />
                      <h1>Stakeholder Engagement </h1>
                      <p>
                        Build strong relationships and transparent
                        communication.
                      </p>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </section>
      </div>

      {/* 2 */}
      <div>
        {" "}
        <section id="section-how" className="section-how-2">
          <table className="section-how-table-2">
            <tbody>
              <tr>
                <td colSpan="1" className="section-why-box"></td>
                <td rowSpan="2" colSpan="2" className="section-why-box">
                  <h1>How we enhance your business?</h1>
                  <div className="box-grid">
                    <div className="box-how">
                      {" "}
                      {/* <FontAwesomeIcon icon={faRupeeSign} /> */}
                      {/* <ArrowUpwardOutlinedIcon className="icon-why" /> */}
                      <img
                        src={Increased_Profitability_icon}
                        alt="Materiality Assessment Icon"
                        className="seges-icons"
                      />
                      <h1>Increased Profitability </h1>
                      <p>Resource optimization and efficiency gains.</p>
                    </div>
                    <div className="box-how">
                      {" "}
                      {/* <SafetyCheckOutlinedIcon className="icon-why" /> */}
                      <img
                        src={ReducedRisk_iconwry}
                        alt="Materiality Assessment Icon"
                        className="seges-icons"
                      />
                      <h1>
                        Reduced <br />
                        Risk{" "}
                      </h1>
                      <p>Mitigating environmental and social challenges.</p>
                    </div>
                  </div>
                  <div className="box-grid">
                    <div className="box-how">
                      {" "}
                      <img
                        src={Enhanced_Reputation_icon}
                        alt="Materiality Assessment Icon"
                        className="seges-icons"
                      />
                      <h1>Enhanced Reputation</h1>
                      <p>
                        Attracting and retaining talent and customers who value
                        sustainability.
                      </p>
                    </div>
                    <div className="box-how">
                      {" "}
                      <img
                        src={future_proof}
                        alt="Materiality Assessment Icon"
                        className="seges-icons"
                      />{" "}
                      <h1>Future-proofed Business </h1>
                      <p>
                        Business prepared for emerging trends and regulations.
                      </p>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </section>
      </div>
    </Slider>
  );
}

export default SectionHow;
