// src/Loader.js
import React from "react";
import "./Loader.css"; // Ensure the CSS is updated

const Loader = ({ fadeOut }) => (
  <div className={`loader-container ${fadeOut ? "fade-out" : ""}`}>
    <div className="circular-loader"></div>
    {/* <h1 className="bg-white">Loading... {countdown} seconds remaining</h1> */}
  </div>
);

export default Loader;
