import React, { useState } from "react";
import "./SectionWhere.css";
function SectionWhere() {
  const [formSubmitted, setFormSubmitted] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;

    fetch(form.action, {
      method: form.method,
      body: new FormData(form),
      headers: {
        Accept: "application/json",
      },
    })
      .then((response) => {
        if (response.ok) {
          setFormSubmitted(true); // Show success tick
          form.reset(); // Clear the form fields

          // Reset the success tick after a brief delay
          setTimeout(() => {
            setFormSubmitted(false);
          }, 2000); // Adjust the delay as needed
        } else {
          alert("There was a problem submitting your form.");
        }
      })
      .catch((error) => {
        alert("There was a problem submitting your form.");
      });
  };

  return (
    <div className="section-where-main">
      <section id="section-where" className="section-where">
        <div className="section-where-title">
          {" "}
          <h1 className="">Where do we start...</h1>
          <p className="">
            Let's work together to find the best solutions for your business.
          </p>
          {/* Contact Form */}
          <form
            action="https://formspree.io/f/xpwajdqz"
            method="POST"
            className="contact-form"
            onSubmit={handleSubmit}
          >
            <label htmlFor="name">Name:</label>
            <input
              type="text"
              id="name"
              name="name"
              Placeholder="Name"
              required
            />

            <label htmlFor="email">Email ID:</label>
            <input type="email" id="email" name="email" required />

            <label htmlFor="company">Company Name:</label>
            <input type="text" id="company" name="company" required />

            <label htmlFor="message">Message:</label>
            <textarea id="message" name="message" rows="2" required></textarea>

            <button type="submit"> {formSubmitted ? "✔ Sent" : "Send"}</button>
          </form>
        </div>

        <table className="section-where-table-1">
          <tfoot>
            <tr>
              {/* Contact Section */}
              <td colSpan="1" rowSpan="1" className="section-where-box">
                <h3>Where can you contact us</h3>
                <h6>
                  <a href="tel:+919112477911">+91 911 2477 911</a>
                </h6>
                <h6>
                  <a href="mailto:expert@seges.earth">expert@seges.earth</a>
                </h6>
                <h6 className="logo-linkedin">
                  {" "}
                  <a
                    href="https://www.linkedin.com/in/jigar-motta-7826a42aa/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 448 512"
                    >
                      <path d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z" />
                    </svg>
                  </a>{" "}
                </h6>
              </td>
              {/* Quick Links Section */}
              <td colSpan="1" rowSpan="1" className="section-where-box">
                <h3>Quick Links</h3>
                <h6>
                  <a href="#section-what">Home</a>
                </h6>
                <h6>
                  <a href="#blogs">Blogs</a>
                </h6>
              </td>
              {/* Careers Section */}
              <td colSpan="1" rowSpan="1" className="section-where-box">
                <h3>Join the Team</h3>
                <h6>
                  <a href="#careers">Careers</a>
                </h6>
              </td>
            </tr>
          </tfoot>
        </table>
      </section>
    </div>
  );
}

export default SectionWhere;
